/* eslint-disable react-hooks/rules-of-hooks */
import useWindowFocus from 'use-window-focus';
import { PricingSurcharge, PricingSurchargeVaryCondition } from '@idearoom/types';
import { ClientDataBranch, pricingBranches } from '../constants/ClientDataBranch';
import { unknownGroup } from '../constants/Group';
import { useAppSelector } from '../hooks';
import {
  useDeleteBranchMutation,
  useGetBranchDiffMergesQuery,
  useGetClientDataBranchDiffQuery,
  useGetClientDataBranchTableDiffQuery,
  useGetClientDataBranchesQuery,
  useGetClientDataCellMetadataQuery,
  useGetClientDataTableDataQuery,
  useGetClientDataTablesColumnsQuery,
} from '../services/clientDataApi';
import { AppState } from '../types/AppState';
import { PricingSheet } from '../types/PricingSheet';
import { Commit } from '../types/Commit';
import { ClientDataType } from '../constants/ClientDataType';
import { SHEDVIEW, getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { CellMetadata, ClientDataBranchMetadata, ClientDataTableRowDiff, SiteDetail } from '../types/ClientData';
import { Region } from '../types/Region';
import { TableData } from '../types/DataGrid';
import { OnlyExistingKeys } from '../types/Repo';
import { CELL_METADATA_TABLE, PRICING_SURCHARGE_TABLE, REGION_TABLE, SITE_DETAIL_TABLE } from '../constants/ClientData';
import { FetchError } from '../types/API';
import { useGetClientPricingSheetsQuery, useGetConditionsQuery } from '../services/pricingApi';
import { findSelectedSurcharge, mapPricingSurchargeConditionOptions } from '../utils/pricingUtils';
import { SALESVIEW } from '../constants/App';
import { getPricingSheetTable } from '../utils/pricingSheetUtils';
import { PricingTab } from '../constants/Pricing';
import { AuthStatus } from '../constants/AuthStatus';

const EMPTY_REGIONS: ReadonlyArray<TableData> = [];
const EMPTY_PRICING_SHEETS_REFERENCE: ReadonlyArray<PricingSheet> = [];
const EMPTY_ACTIVE_BRANCHES_REFERENCE: ReadonlyArray<ClientDataBranch> = [];
const EMPTY_CLIENT_TABLE_COLUMNS_REFERENCE: Readonly<{ [key: string]: string[] }> = {};
const EMPTY_COMMITS_REFERENCE: ReadonlyArray<Commit> = [];

const EMPTY_CELL_METADATA_REFERENCE: ReadonlyArray<CellMetadata> = [];
const EMPTY_SELECTED_TABLE_DATA_DIFF_REFERENCE: ReadonlyArray<ClientDataTableRowDiff> = [];
const EMPTY_SELECTED_PRICING_SURCHARGE_REFERENCE: ReadonlyArray<PricingSurcharge> = [];

type UsePricingRepoReturn<
  UsePricingBaseSheet,
  UsePricingBranchMergeCommit,
  UseActiveBranches,
  UseSupplierRegions,
  UseCellMetadataType,
  UseCellMetadataDiffType,
  UsePricingEnabled,
  UsePricingSurchargeVaryConditions,
  UseSelectedPricingSurcharge,
  UsePricingBaseDiffs,
> = OnlyExistingKeys<{
  pricingBaseSheets: UsePricingBaseSheet extends true ? PricingSheet[] : never;
  isLoadingPricingBaseSheets: UsePricingBaseSheet extends true ? boolean : never;
  isFetchingPricingBaseSheets: UsePricingBaseSheet extends true ? boolean : never;
  isErrorPricingBaseSheets: UsePricingBaseSheet extends true ? boolean : never;
  isPricingBranchDeleted: UsePricingBaseSheet extends true ? boolean : never;
  pricingBranchMergeCommit: UsePricingBranchMergeCommit extends true ? Commit : never;
  isLoadingBranches: UseActiveBranches extends true ? boolean : never;
  activeBranches: UseActiveBranches extends true ? ClientDataBranchMetadata[] : never;
  supplierRegions: UseSupplierRegions extends true ? Region[] : never;
  isLoadingRegions: UseSupplierRegions extends true ? boolean : never;
  cellMetadata: UseCellMetadataType extends true ? CellMetadata[] : never;
  cellMetadataDiff: UseCellMetadataDiffType extends true ? CellMetadata[] : never;
  pricingEnableClientManaged: UsePricingEnabled extends true ? number : never;
  pricingEnableClientUpdates: UsePricingEnabled extends true ? number : never;
  isLoadingPricingEnabled: UsePricingEnabled extends true ? boolean : never;
  pricingSurchargeVaryConditions: UsePricingSurchargeVaryConditions extends true
    ? PricingSurchargeVaryCondition[]
    : never;
  isLoadingPricingSurchargeVaryConditions: UsePricingSurchargeVaryConditions extends true ? boolean : never;
  selectedPricingSurcharge: UseSelectedPricingSurcharge extends true ? PricingSurcharge : never;
  isLoadingSelectedPricingSurcharge: UseSelectedPricingSurcharge extends true ? boolean : never;
  isInitializingSelectedPricingSurcharge: UseSelectedPricingSurcharge extends true ? boolean : never;
  pricingBaseDiffs: UsePricingBaseDiffs extends true ? { table: string; changes: ClientDataTableRowDiff[] }[] : never;
  isLoadingPricingBaseDiffs: UsePricingBaseDiffs extends true ? boolean : never;
}>;

export const usePricingRepo = <
  UsePricingBaseSheet extends boolean = false,
  UsePricingBranchMergeCommit extends boolean = false,
  UseActiveBranches extends boolean = false,
  UseSupplierRegions extends boolean = false,
  UseCellMetadataType extends boolean = false,
  UseCellMetadataDiffType extends boolean = false,
  UsePricingEnabled extends boolean = false,
  UsePricingSurchargeVaryConditions extends boolean = false,
  UseSelectedPricingSurcharge extends boolean = false,
  UsePricingBaseDiffs extends boolean = false,
>({
  usePricingSheetBase,
  usePricingBranchMergeCommit,
  useBranches,
  useSupplierRegions,
  useCellMetadata,
  useCellMetadataDiff,
  usePricingEnabled,
  usePricingSurchargeVaryConditions,
  useSelectedPricingSurcharge,
  usePricingBaseDiffs,
}: {
  usePricingSheetBase?: UsePricingBaseSheet;
  usePricingBranchMergeCommit?: UsePricingBranchMergeCommit;
  useBranches?: UseActiveBranches;
  useSupplierRegions?: UseSupplierRegions;
  useCellMetadata?: UseCellMetadataType;
  useCellMetadataDiff?: UseCellMetadataDiffType;
  usePricingEnabled?: UsePricingEnabled;
  usePricingSurchargeVaryConditions?: UsePricingSurchargeVaryConditions;
  useSelectedPricingSurcharge?: UseSelectedPricingSurcharge;
  usePricingBaseDiffs?: UsePricingBaseDiffs;
} = {}): UsePricingRepoReturn<
  UsePricingBaseSheet,
  UsePricingBranchMergeCommit,
  UseActiveBranches,
  UseSupplierRegions,
  UseCellMetadataType,
  UseCellMetadataDiffType,
  UsePricingEnabled,
  UsePricingSurchargeVaryConditions,
  UseSelectedPricingSurcharge,
  UsePricingBaseDiffs
> => {
  const clientId = useAppSelector(
    ({ viewer: { selectedClientId, selectedTabId }, clientData: { clientId: clientDataClientId } }: AppState) =>
      getClientIdFromClientSupplier(clientDataClientId || selectedTabId || selectedClientId || ''),
  );
  const { authStatus, group: { groupId } = unknownGroup } = useAppSelector((state: AppState) => state?.currentUser);
  const isSignedIn = authStatus === AuthStatus.SignedIn;
  const pricingBaseTable = clientId.startsWith(SHEDVIEW) ? 'basePrice' : 'pricingBase';
  const isWindowFocused = useWindowFocus();

  const { clientDataType, clientDataBranch } = useAppSelector((state: AppState) => state?.clientData);
  const {
    base: { pricingDataBranch: pricingBaseDataBranch = ClientDataBranch.Main },
    surcharge: { supplierKey = '' },
  } = useAppSelector((state) => state?.pricing);
  const [, { isLoading: isDeletingBranch }] = useDeleteBranchMutation({
    fixedCacheKey: 'revert',
  });

  const result: any = { isPricingBranchDeleted: false };

  if (usePricingSheetBase || useBranches) {
    // This is needed for getting active branches
    const { data: _clientTableColumns = EMPTY_CLIENT_TABLE_COLUMNS_REFERENCE } = useGetClientDataTablesColumnsQuery(
      { dataType: clientDataType, clientId },
      {
        skip: !isSignedIn || !clientDataType || !clientId,
        refetchOnFocus: false,
      },
    );
    const {
      currentData: activeBranches = EMPTY_ACTIVE_BRANCHES_REFERENCE,
      isFetching,
      isLoading,
    } = useGetClientDataBranchesQuery(
      { dataType: clientDataType, clientId, groupId },
      {
        skip: !isSignedIn || !clientId || clientId === SALESVIEW,
        // refetch every 2 minutes
        // stops pooling if window is not focused
        pollingInterval: isWindowFocused ? 2 * 60 * 1000 : undefined,
      },
    );

    result.activeBranches = activeBranches;
    result.isLoadingBranches = isFetching || isLoading;
  }

  if (usePricingEnabled) {
    const { isFetching: isLoadingPricingEnabled, currentData: siteDetail = [] } = useGetClientDataTableDataQuery(
      {
        dataType: ClientDataType.Vendor,
        clientId,
        groupId,
        branch: ClientDataBranch.Main,
        table: SITE_DETAIL_TABLE,
      },
      {
        skip: !isSignedIn || !clientId || isDeletingBranch,
      },
    );

    result.isLoadingPricingEnabled = isLoadingPricingEnabled;
    result.pricingEnableClientManaged =
      siteDetail.length > 0 ? (siteDetail[0] as SiteDetail).pricingEnableClientManaged : 0;
    result.pricingEnableClientUpdates =
      siteDetail.length > 0 ? (siteDetail[0] as SiteDetail).pricingEnableClientUpdates : 0;
  }

  if (usePricingSheetBase) {
    const skip = !isSignedIn || !clientId || isDeletingBranch;
    const {
      isLoading: isLoadingPricingBaseSheets,
      isFetching: isFetchingPricingBaseSheets,
      currentData: pricingBaseSheets = EMPTY_PRICING_SHEETS_REFERENCE,
      isError: isErrorPricingBaseSheets,
      error,
    } = useGetClientPricingSheetsQuery(
      {
        clientId,
        groupId,
        branch: pricingBaseDataBranch,
      },
      {
        skip,
        refetchOnMountOrArgChange: true,
      },
    );

    result.pricingBaseSheets = pricingBaseSheets;
    result.isLoadingPricingBaseSheets = skip || isLoadingPricingBaseSheets;
    result.isErrorPricingBaseSheets = isErrorPricingBaseSheets;
    result.isFetchingPricingBaseSheets = isFetchingPricingBaseSheets;

    if (error) {
      const { status, data } = error as FetchError;
      if (status === 404 && data.includes(`${ClientDataBranch.Pricing} does not exist`)) {
        result.isPricingBranchDeleted = true;
      }
    }
  }

  if (usePricingBranchMergeCommit) {
    const skip = !isSignedIn || !clientId || isDeletingBranch || pricingBaseDataBranch !== ClientDataBranch.Pricing;
    const { currentData: pricingBranchMergeCommit = EMPTY_COMMITS_REFERENCE } = useGetBranchDiffMergesQuery(
      {
        dataType: ClientDataType.Supplier,
        clientId,
        groupId,
        branch: ClientDataBranch.Pricing,
        limit: 1,
        fromBranch: ClientDataBranch.Main,
        tables: [pricingBaseTable],
      },
      {
        skip,
        refetchOnMountOrArgChange: true,
        // refetch every minute
        // stops pooling if window is not focused
        pollingInterval: isWindowFocused ? 60000 : undefined,
      },
    );
    result.pricingBranchMergeCommit = pricingBranchMergeCommit ? pricingBranchMergeCommit[0] : undefined;
  }

  if (useSupplierRegions) {
    const { currentData: regions = EMPTY_REGIONS, isFetching: isLoadingRegions } = useGetClientDataTableDataQuery(
      {
        dataType: ClientDataType.Supplier,
        clientId,
        groupId,
        branch: ClientDataBranch.Main,
        table: REGION_TABLE,
        enabledOnly: true,
      },
      {
        skip: !isSignedIn || !clientId || isDeletingBranch,
        refetchOnMountOrArgChange: true,
        pollingInterval: isWindowFocused ? 2 * 60 * 1000 : undefined,
      },
    );

    result.isLoadingRegions = isLoadingRegions;
    result.supplierRegions = regions;
  }

  if (useCellMetadata) {
    const { currentData: cellMetadata = EMPTY_CELL_METADATA_REFERENCE } = useGetClientDataCellMetadataQuery(
      {
        dataType: ClientDataType.Supplier,
        clientId,
        table: pricingBaseTable,
        branch: pricingBaseDataBranch || ClientDataBranch.Main,
        groupId,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !isSignedIn || !clientId || isDeletingBranch,
      },
    );
    result.cellMetadata = cellMetadata;
  }

  if (useCellMetadataDiff) {
    const { currentData: cellMetadataDiff = EMPTY_SELECTED_TABLE_DATA_DIFF_REFERENCE } =
      useGetClientDataBranchTableDiffQuery(
        {
          dataType: ClientDataType.Supplier,
          clientId,
          branch: pricingBaseDataBranch || ClientDataBranch.Main,
          table: CELL_METADATA_TABLE,
        },
        {
          skip: !isSignedIn || !clientId || isDeletingBranch,
          refetchOnMountOrArgChange: true,
        },
      );
    result.cellMetadataDiff = cellMetadataDiff;
  }

  if (usePricingSurchargeVaryConditions) {
    const { currentData: conditions } = useGetConditionsQuery(
      {
        clientId,
      },
      {
        skip: !isSignedIn || !clientId,
      },
    );
    result.pricingSurchargeVaryConditions = mapPricingSurchargeConditionOptions(conditions);
  }

  if (useSelectedPricingSurcharge) {
    const { currentData: surchargeTableData, isFetching: isLoadingSelectedPricingSurcharge } =
      useGetClientDataTableDataQuery(
        {
          dataType: ClientDataType.Vendor,
          clientId,
          groupId,
          branch: clientDataBranch || ClientDataBranch.Main,
          table: PRICING_SURCHARGE_TABLE,
        },
        {
          skip:
            !isSignedIn || !clientId || !clientDataBranch || isDeletingBranch || !pricingBranches.has(clientDataBranch),
          refetchOnMountOrArgChange: true,
        },
      );
    result.selectedPricingSurcharge =
      findSelectedSurcharge(surchargeTableData || [], clientId, supplierKey) ||
      EMPTY_SELECTED_PRICING_SURCHARGE_REFERENCE;
    result.isLoadingSelectedPricingSurcharge = isLoadingSelectedPricingSurcharge;
    result.isInitializingSelectedPricingSurcharge = isLoadingSelectedPricingSurcharge && !surchargeTableData;
  }

  if (usePricingBaseDiffs) {
    const tables = [getPricingSheetTable(clientId, PricingTab.Base, undefined) || ''].filter(Boolean);
    const { currentData: pricingBaseDiffs = [], isLoading: isLoadingPricingBaseDiffs } =
      useGetClientDataBranchDiffQuery(
        {
          clientId,
          dataType: ClientDataType.Supplier,
          branch: pricingBaseDataBranch || ClientDataBranch.Main,
          tables,
        },
        {
          skip: !isSignedIn || !clientId || isDeletingBranch || tables.length === 0,
          refetchOnMountOrArgChange: true,
        },
      );
    result.isLoadingPricingBaseDiffs = isLoadingPricingBaseDiffs;
    result.pricingBaseDiffs = pricingBaseDiffs;
  }

  return result;
};
