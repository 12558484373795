/* eslint-disable react-hooks/rules-of-hooks */
import useWindowFocus from 'use-window-focus';
import { CELL_METADATA_TABLE } from '../constants/ClientData';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { useAppSelector } from '../hooks';
import {
  useGetClientDataBranchTableDiffQuery,
  useGetClientDataBranchesQuery,
  useGetClientDataCellMetadataQuery,
  useGetClientDataTableDataQuery,
  useGetClientDataTableMetadataQuery,
  useGetClientDataTablesColumnsQuery,
  useGetClientDataTablesQuery,
  useDeleteBranchMutation,
  useGetVendorDataQuery,
} from '../services/clientDataApi';
import {
  CellMetadata,
  ClientDataBranchMetadata,
  ClientDataTableRowDiff,
  ClientTable,
  TableMetadata,
} from '../types/ClientData';
import { TableData } from '../types/DataGrid';
import { unknownGroup } from '../constants/Group';
import { OnlyExistingKeys } from '../types/Repo';
import { Vendor } from '../types/VendorData';
import { ClientDataType } from '../constants/ClientDataType';
import { SALESVIEW } from '../constants/App';
import { AuthStatus } from '../constants/AuthStatus';

/**
 * Using constant empty var references so the hook doesn't generate new references on every render
 * Allowing us to use the returned values from this hook inside useEffect params.
 */
const EMPTY_ACTIVE_BRANCHES_REFERENCE: ReadonlyArray<ClientDataBranchMetadata> = [];
const EMPTY_CLIENT_TABLES_REFERENCE: ReadonlyArray<ClientTable> = [];
const EMPTY_CLIENT_TABLE_COLUMNS_REFERENCE: Readonly<{ [key: string]: string[] }> = {};
const EMPTY_CELL_METADATA_REFERENCE: ReadonlyArray<CellMetadata> = [];
const EMPTY_SELECTED_TABLE_DATA_REFERENCE: ReadonlyArray<TableData> = [];
const EMPTY_SELECTED_TABLE_DATA_DIFF_REFERENCE: ReadonlyArray<ClientDataTableRowDiff> = [];
const EMPTY_VENDOR_DATA_REFERENCE: Readonly<Vendor> = {};

type UseClientDataRepoReturn<
  UseBranchesType,
  UseClientTablesType,
  UseClientTablesColumnsType,
  UseTableMetadataType,
  UseCellMetadataType,
  UseSelectedTableDataType,
  UseSelectedTableDataDiffType,
  UseCellMetadataDiffType,
  UseVendorDataType,
> = OnlyExistingKeys<{
  activeBranches: UseBranchesType extends true ? ClientDataBranchMetadata[] : never;
  clientTables: UseClientTablesType extends true ? ClientTable[] : never;
  isLoadingClientTables: UseClientTablesType extends true ? boolean : never;
  clientTableColumns: UseClientTablesColumnsType extends true ? { [key: string]: string[] } : never;
  isLoadingClientTableColumns: UseClientTablesColumnsType extends true ? boolean : never;
  tableMetadata: UseTableMetadataType extends true ? TableMetadata | undefined : never;
  isInitializingTableMetadata: UseTableMetadataType extends true ? boolean : never;
  cellMetadata: UseCellMetadataType extends true ? CellMetadata[] : never;
  selectedTableData: UseSelectedTableDataType extends true ? TableData[] : never;
  isLoadingSelectedTableData: UseSelectedTableDataType extends true ? boolean : never;
  /**
   * Is fetching from the server and there's no cached data yet
   */
  isInitializingSelectedTableData: UseSelectedTableDataType extends true ? boolean : never;
  selectedTableDataDiff: UseSelectedTableDataDiffType extends true ? ClientDataTableRowDiff[] : never;
  cellMetadataDiff: UseCellMetadataDiffType extends true ? ClientDataTableRowDiff[] : never;
  vendorData: UseVendorDataType extends true ? Vendor : never;
  isLoadingVendorData: UseVendorDataType extends true ? boolean : never;
}>;

/**
 * Hook for fetching the most common data for the currently selected grid.
 * The useData... params are used so we don't end up subscribing this component to unnecessary queries.
 *
 * @param useData param used to specify the data the component needs to fetch.
 */
export const useClientDataRepo = <
  UseBranchesType extends boolean = false,
  UseClientTablesType extends boolean = false,
  UseClientTablesColumnsType extends boolean = false,
  UseTableMetadataType extends boolean = false,
  UseCellMetadataType extends boolean = false,
  UseSelectedTableDataType extends boolean = false,
  UseSelectedTableDataDiffType extends boolean = false,
  UseCellMetadataDiffType extends boolean = false,
  UseVendorDataType extends boolean = false,
>({
  useBranches,
  useClientTables,
  useClientTablesColumns,
  useTableMetadata,
  useCellMetadata,
  useSelectedTableData,
  useSelectedTableDataDiff,
  useCellMetadataDiff,
  useVendorData,
}: {
  useBranches?: UseBranchesType;
  useClientTables?: UseClientTablesType;
  useClientTablesColumns?: UseClientTablesColumnsType;
  useTableMetadata?: UseTableMetadataType;
  useCellMetadata?: UseCellMetadataType;
  useSelectedTableData?: UseSelectedTableDataType;
  useSelectedTableDataDiff?: UseSelectedTableDataDiffType;
  useCellMetadataDiff?: UseCellMetadataDiffType;
  useVendorData?: UseVendorDataType;
} = {}): UseClientDataRepoReturn<
  UseBranchesType,
  UseClientTablesType,
  UseClientTablesColumnsType,
  UseTableMetadataType,
  UseCellMetadataType,
  UseSelectedTableDataType,
  UseSelectedTableDataDiffType,
  UseCellMetadataDiffType,
  UseVendorDataType
> => {
  const { clientDataType, clientId, selectedTable, clientDataBranch } = useAppSelector((state) => state?.clientData);
  const { authStatus, group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const isSignedIn = authStatus === AuthStatus.SignedIn;
  const [, { isLoading: isDeletingBranch }] = useDeleteBranchMutation({
    fixedCacheKey: 'revert',
  });

  const result: any = {};
  if (useBranches) {
    const isWindowFocused = useWindowFocus();
    const { currentData: activeBranches = EMPTY_ACTIVE_BRANCHES_REFERENCE } = useGetClientDataBranchesQuery(
      { dataType: clientDataType, clientId, groupId },
      {
        skip: !isSignedIn || !clientDataType || !clientId || clientId === SALESVIEW,
        // refetch every 2 minutes
        // stops pooling if window is not focused
        pollingInterval: isWindowFocused ? 2 * 60 * 1000 : undefined,
      },
    );
    result.activeBranches = activeBranches;
  }

  if (useClientTables || useSelectedTableData || useSelectedTableDataDiff) {
    const { data: clientTables = EMPTY_CLIENT_TABLES_REFERENCE, isFetching: isLoadingClientTables } =
      useGetClientDataTablesQuery(
        { dataType: clientDataType, groupId, clientId },
        {
          skip: !clientDataType || !clientId,
          refetchOnFocus: false,
        },
      );
    result.clientTables = clientTables;
    result.isLoadingClientTables = isLoadingClientTables;
  }

  if (useClientTablesColumns) {
    const { data: clientTableColumns = EMPTY_CLIENT_TABLE_COLUMNS_REFERENCE, isFetching: isLoadingClientTableColumns } =
      useGetClientDataTablesColumnsQuery(
        { dataType: clientDataType, clientId },
        {
          skip: !clientDataType || !clientId,
          refetchOnFocus: false,
        },
      );
    result.clientTableColumns = clientTableColumns;
    result.isLoadingClientTableColumns = isLoadingClientTableColumns;
  }

  if (useCellMetadata) {
    const { currentData: cellMetadata = EMPTY_CELL_METADATA_REFERENCE } = useGetClientDataCellMetadataQuery(
      {
        dataType: clientDataType,
        clientId,
        table: selectedTable,
        branch: clientDataBranch || ClientDataBranch.Main,
        groupId,
      },
      {
        skip: !clientDataType || !clientId || !selectedTable || !clientDataBranch || isDeletingBranch,
      },
    );
    result.cellMetadata = cellMetadata;
  }

  if (useTableMetadata) {
    const { currentData: tableMetadata, isFetching: isLoadingTableMetadata } = useGetClientDataTableMetadataQuery(
      { dataType: clientDataType, clientId, table: selectedTable, branch: clientDataBranch || ClientDataBranch.Main },
      {
        skip: !clientDataType || !clientId || !selectedTable || !clientDataBranch,
      },
    );
    result.tableMetadata = tableMetadata;
    result.isInitializingTableMetadata = isLoadingTableMetadata && !tableMetadata;
  }

  if (useSelectedTableData) {
    const { currentData: selectedTableData, isFetching: isLoadingSelectedTableData } = useGetClientDataTableDataQuery(
      {
        dataType: clientDataType,
        clientId,
        groupId,
        branch: clientDataBranch || ClientDataBranch.Main,
        table: selectedTable,
      },
      {
        skip:
          !isSignedIn ||
          !clientDataType ||
          !clientId ||
          !selectedTable ||
          !clientDataBranch ||
          result.isLoadingClientTables ||
          isDeletingBranch,
        refetchOnMountOrArgChange: true,
      },
    );
    result.selectedTableData = selectedTableData || EMPTY_SELECTED_TABLE_DATA_REFERENCE;
    result.isLoadingSelectedTableData = isLoadingSelectedTableData;
    result.isInitializingSelectedTableData = isLoadingSelectedTableData && !selectedTableData;
  }

  if (useSelectedTableDataDiff) {
    const { currentData: selectedTableDataDiff = EMPTY_SELECTED_TABLE_DATA_DIFF_REFERENCE } =
      useGetClientDataBranchTableDiffQuery(
        {
          dataType: clientDataType,
          clientId,
          branch: clientDataBranch || ClientDataBranch.Main,
          table: selectedTable,
        },
        {
          skip:
            !isSignedIn ||
            !clientDataType ||
            !clientId ||
            !selectedTable ||
            !clientDataBranch ||
            result.isLoadingClientTables ||
            isDeletingBranch,
          refetchOnMountOrArgChange: true,
        },
      );
    result.selectedTableDataDiff = selectedTableDataDiff;
  }

  if (useCellMetadataDiff) {
    const { currentData: cellMetadataDiff = EMPTY_SELECTED_TABLE_DATA_DIFF_REFERENCE } =
      useGetClientDataBranchTableDiffQuery(
        {
          dataType: clientDataType,
          clientId,
          branch: clientDataBranch || ClientDataBranch.Main,
          table: CELL_METADATA_TABLE,
        },
        {
          skip:
            !isSignedIn ||
            !clientDataType ||
            !clientId ||
            !selectedTable ||
            !clientDataBranch ||
            result.isLoadingClientTables,
          refetchOnMountOrArgChange: true,
        },
      );
    result.cellMetadataDiff = cellMetadataDiff;
  }

  if (useVendorData) {
    const { currentData: vendorData = EMPTY_VENDOR_DATA_REFERENCE, isFetching: isLoadingVendorData } =
      useGetVendorDataQuery(
        { clientId },
        {
          skip: !clientId || clientDataType === ClientDataType.Reference,
        },
      );
    result.vendorData = vendorData;
    result.isLoadingVendorData = isLoadingVendorData;
  }

  return result;
};
