import React, { useEffect, useState } from 'react';
import { Card, CardContent, Chip, FormGroup, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import {
  PricingSurchargeCalculationType,
  PricingSurchargeConditionType,
  PricingSurchargeRule as PricingSurchargeRuleType,
  PricingSurchargeVaryCondition,
} from '@idearoom/types';
import { AppState } from '../types/AppState';
import { PricingSurchargeKeyCondition, PricingSurchargeRangeCondition } from '../types/PricingSurcharge';
import {
  PricingSurchargeErrorType,
  SurchargeCalculationProperty,
  SurchargeRuleProperty,
  SurchargeUpdateProperty,
} from '../constants/PricingSurcharge';
import { PricingSurchargeKeyConditionMenu } from './PricingSurchargeKeyConditionMenu';
import { PricingSurchargeRangeConditionMenu } from './PricingSurchargeRangeConditionMenu';
import { Red as RedColor, LightText as LightTextColor, RedMuted, Black } from '../constants/ChipColors';
import { formatPrice, getCurrencySymbol, parseRoundTo } from '../utils/pricingUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { PricingSurchargeSubtotalConditionMenu } from './PricingSurchargeSubtotalConditionMenu';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { updateSurchargeProperty } from '../ducks/pricingSlice';
import { areObjectsEqual } from '../utils/objectUtils';

const useStyles = makeStyles({
  card: {
    backgroundColor: '#f0f0f0',
    width: '100%',
  },
  textField: {
    '& > *': {
      width: '15ch',
    },
    marginRight: '10px',
  },
  removeIcon: {
    color: 'darkred',
    float: 'right',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  spacing: {
    marginTop: '10px',
  },
  chipError: {
    backgroundColor: RedColor,
    color: LightTextColor,
    '&:focus': {
      backgroundColor: RedMuted,
      color: Black,
    },
    '&:hover': {
      backgroundColor: RedMuted,
      color: Black,
    },
  },
});

export const PricingSurchargeRule: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const {
    viewer: { currency },
    pricing: {
      surcharge: { surchargeErrors: validationErrors },
    },
  } = useAppSelector((state: AppState) => state);
  const { selectedPricingSurcharge } = usePricingRepo({
    useBranches: true,
    useSelectedPricingSurcharge: true,
  });
  const { rules, key: pricingSelection } = selectedPricingSurcharge;

  const { pricingSurchargeVaryConditions: conditionOptions } = usePricingRepo({
    usePricingSurchargeVaryConditions: true,
  });

  const [activePricingCondition, setActivePricingCondition] = useState<PricingSurchargeVaryCondition>();
  const [activeSurchargeRule, setActiveSurchargeRule] = useState<PricingSurchargeRuleType>();
  const [pricingConditionMenuAnchorEl, setPricingConditionMenuAnchorEl] = useState<null | HTMLDivElement>(null);
  const [pricingConditionRangeMenuAnchorEl, setPricingConditionRangeMenuAnchorEl] = useState<null | HTMLDivElement>(
    null,
  );
  const [pricingConditionSubtotalMenuAnchorEl, setPricingConditionSubtotalMenuAnchorEl] =
    useState<null | HTMLDivElement>(null);

  const handlePricingConditionMenuClose = (): void => setPricingConditionMenuAnchorEl(null);
  const handlePricingConditionSizeMenuClose = (): void => setPricingConditionRangeMenuAnchorEl(null);
  const handlePricingConditionSubtotalMenuClose = (): void => setPricingConditionSubtotalMenuAnchorEl(null);

  const handleConditionChipClick = (
    event: any,
    rule: PricingSurchargeRuleType,
    condition: PricingSurchargeVaryCondition,
  ): void => {
    setActivePricingCondition(condition);
    setActiveSurchargeRule(rule);
    if (condition.value === PricingSurchargeConditionType.Subtotal) {
      setPricingConditionSubtotalMenuAnchorEl(event ? event.target : null);
    } else if (condition.range) {
      setPricingConditionRangeMenuAnchorEl(event ? event.target : null);
    } else {
      setPricingConditionMenuAnchorEl(event ? event.target : null);
    }
  };

  const createLabelForConditionChip = (
    condition: PricingSurchargeVaryCondition,
    conditions: PricingSurchargeKeyCondition[] | PricingSurchargeRangeCondition[],
  ): string => {
    const labels: string[] = [];
    if (condition.value === PricingSurchargeConditionType.Subtotal) {
      const { minimum = 0, maximum = 0 } =
        (conditions as PricingSurchargeRangeCondition[]).find((sc) => sc.type === condition.value) || {};
      labels.push(`${formatPrice(minimum, currency)} to ${formatPrice(maximum, currency)}`);
    } else if (condition.range) {
      const { minimum = 0, maximum = 0 } =
        (conditions as PricingSurchargeRangeCondition[]).find((sc) => sc.type === condition.value) || {};
      labels.push(
        ...condition.options
          .slice(0)
          .sort((a, b) => (Number(a.key) > Number(b.key) ? 1 : -1))
          .filter((option) => option.label !== undefined && [minimum, maximum].includes(Number(option.key)))
          .map((option) => option.label || ''),
      );
    } else {
      const { keys = [] } =
        (conditions as PricingSurchargeKeyCondition[]).find((sc) => sc.type === condition.value) || {};
      labels.push(
        ...condition.options
          .filter(
            (option, i) =>
              // Don't include duplicate labels
              keys.includes(option.key) && !condition.options.slice(0, i).some((o) => o.label === option.label),
          )
          .map((option) => option.label || ''),
      );
    }
    return `${t(condition.label)}${labels.length > 0 ? ': ' : ''}${
      condition.range ? labels.join(' - ') : labels.join(', ')
    }`;
  };

  const removeRule = (id: number): void => {
    dispatch(updateSurchargeProperty([{ property: SurchargeUpdateProperty.Rules, value: { id, isDelete: true } }]));
  };

  const handleCalculationValueChange = (
    { id }: PricingSurchargeRuleType,
    property: SurchargeCalculationProperty,
    newValue: string,
    percentage = false,
  ): void => {
    let parsedValue = percentage ? parseFloat(newValue) / 100 : parseFloat(newValue);
    if (property === SurchargeCalculationProperty.RoundTo) parsedValue = parseRoundTo(newValue);
    if (id !== undefined) {
      dispatch(
        updateSurchargeProperty([
          {
            property: SurchargeUpdateProperty.Rules,
            value: {
              id,
              property: SurchargeRuleProperty.Calculation,
              value: { property, value: parsedValue },
            },
          },
        ]),
      );
    }
  };

  useEffect(() => {
    // when rules have been updated in state and one is selected "re-load" it by it's id
    if (activeSurchargeRule) {
      const newRule = rules.find((rule) => activeSurchargeRule.id === rule.id);
      if (!areObjectsEqual(activeSurchargeRule, newRule)) {
        setActiveSurchargeRule(newRule);
      }
    }
  }, [rules, activeSurchargeRule]);

  return (
    <>
      <PricingSurchargeKeyConditionMenu
        pricingSurchargeVaryCondition={activePricingCondition}
        rule={activeSurchargeRule}
        anchorEl={pricingConditionMenuAnchorEl}
        onClose={handlePricingConditionMenuClose}
      />
      <PricingSurchargeRangeConditionMenu
        pricingSurchargeVaryCondition={activePricingCondition}
        rule={activeSurchargeRule}
        anchorEl={pricingConditionRangeMenuAnchorEl}
        onClose={handlePricingConditionSizeMenuClose}
      />
      <PricingSurchargeSubtotalConditionMenu
        pricingSurchargeVaryCondition={activePricingCondition}
        rule={activeSurchargeRule}
        anchorEl={pricingConditionSubtotalMenuAnchorEl}
        onClose={handlePricingConditionSubtotalMenuClose}
      />
      {rules.length > 0 &&
        rules.map((rule, index) => (
          <Grid key={`rule-grid-${rule.id}`} container className={classes.spacing}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid>
                    <Grid container direction="row" spacing={2} alignItems="center">
                      <Grid item>
                        <Typography>
                          {t(I18nKeys.PricingRuleTitle, {
                            selection:
                              pricingSelection === PricingSurchargeCalculationType.Surcharge
                                ? t(I18nKeys.PricingTypeSurcharge)
                                : t(I18nKeys.PricingTypeChange),
                          })}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container direction="row" spacing={1}>
                          {conditionOptions
                            .filter((option) => rule.conditions.some((condition) => condition.type === option.value))
                            .map((condition) => (
                              <Grid key={`${condition.value}-grid`} item>
                                <Chip
                                  key={`${condition.value}-chip`}
                                  deleteIcon={<ArrowDropDownIcon />}
                                  onDelete={(event: any): void => handleConditionChipClick(event, rule, condition)}
                                  onClick={(event: any): void => handleConditionChipClick(event, rule, condition)}
                                  label={createLabelForConditionChip(
                                    condition,
                                    rule.conditions as PricingSurchargeKeyCondition[],
                                  )}
                                  className={
                                    validationErrors.some(
                                      (error) =>
                                        error.ruleId === rule.id &&
                                        error.errorType === PricingSurchargeErrorType.Condition &&
                                        error.type === condition.value,
                                    )
                                      ? classes.chipError
                                      : undefined
                                  }
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid>
                      <FormGroup row className={classes.spacing}>
                        <TextField
                          className={classes.textField}
                          variant="standard"
                          label={t(I18nKeys.PricingPercentChange)}
                          type="number"
                          value={
                            rule.calculation.percentChange
                              ? parseFloat((rule.calculation.percentChange * 100).toFixed(7)).toString()
                              : rule.calculation.percentChange
                          }
                          onChange={(event): void => {
                            handleCalculationValueChange(
                              rule,
                              SurchargeCalculationProperty.PercentChange,
                              event.target.value,
                              true,
                            );
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                          }}
                          error={
                            validationErrors.some(
                              (error) =>
                                error.ruleId === rule.id &&
                                error.errorType === PricingSurchargeErrorType.Calculation &&
                                error.type === SurchargeCalculationProperty.PercentChange,
                            )
                              ? true
                              : undefined
                          }
                        />
                        {pricingSelection && pricingSelection === PricingSurchargeCalculationType.Surcharge && (
                          <TextField
                            className={classes.textField}
                            variant="standard"
                            label={t(I18nKeys.PricingAmountChange)}
                            type="number"
                            value={rule.calculation.amountChange}
                            onChange={(event): void => {
                              handleCalculationValueChange(
                                rule,
                                SurchargeCalculationProperty.AmountChange,
                                event.target.value,
                              );
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>
                              ),
                            }}
                            error={
                              validationErrors.some(
                                (error) =>
                                  error.ruleId === rule.id &&
                                  error.errorType === PricingSurchargeErrorType.Calculation &&
                                  error.type === SurchargeCalculationProperty.AmountChange,
                              )
                                ? true
                                : undefined
                            }
                          />
                        )}
                        <TextField
                          className={classes.textField}
                          variant="standard"
                          label={t(I18nKeys.PricingRoundToNearest)}
                          type="number"
                          value={rule.calculation.roundTo}
                          onChange={(event): void =>
                            handleCalculationValueChange(rule, SurchargeCalculationProperty.RoundTo, event.target.value)
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>
                            ),
                          }}
                          error={
                            validationErrors.some(
                              (error) =>
                                error.ruleId === rule.id &&
                                error.errorType === PricingSurchargeErrorType.Calculation &&
                                error.type === SurchargeCalculationProperty.RoundTo,
                            )
                              ? true
                              : undefined
                          }
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid className={classes.removeIcon}>
                    {index > 0 && (
                      <RemoveCircleIcon className={classes.removeIcon} onClick={(): void => removeRule(rule.id)} />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
    </>
  );
};
